<template>
  <div>
    <div v-show="$route.meta.secondRoute">
      <div class="btn-box flex">
        <a-button class="btn" icon="plus" type="primary" @click="modalVisible=true">新建阶段</a-button>
        <div class="switch flex">开启学习规划：<a-switch :checked="stageSwitch" @change="onSwitch" /></div>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" row-key="stageNo" :pagination="false" :loading="tableLoading">
        <span slot="number" slot-scope="number, record, index">
          <span>{{ index + 1 }}</span>
        </span>
        <span slot="putawayFlag" slot-scope="putawayFlag, recode">
          {{ +putawayFlag === 0 ? '未上架' : '已上架'}}
        </span>
        <span slot="operation" slot-scope=" operation, record">
          <a class="margin_right20" @click="onPut(record)">{{ record.putawayFlag ? '下架' : '上架' }}</a>
          <a class="margin_right20" @click="showModal(record)">编辑</a>
          <router-link :to="{ name: 'StudyPlanLabel', params: {courseNo: courseNo, stageNo: record.stageNo}}" class="margin_right20">标签管理</router-link>
        </span>
      </a-table>
      <!-- 添加/编辑 -->
      <a-modal
        title="添加/编辑"
        :visible="modalVisible"
        :confirm-loading="confirmLoading"
        :footer="null"
        :destroy-on-close="true"
        @cancel="onCancel"
      >
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="阶段名称" ref="stageTitle" prop="stageTitle">
            <a-input v-model="ruleForm.stageTitle" placeholder="请输入阶段名称" />
          </a-form-model-item>
          <a-form-model-item label="排序" ref="sort" prop="sort">
            <a-input v-model="ruleForm.sort" placeholder="数字越大，顺序越靠前" />
          </a-form-model-item>
          <a-form-model-item label="上架设置" ref="putawayFlag" prop="putawayFlag">
            <a-radio-group v-model="ruleForm.putawayFlag">
              <a-radio :value="0">
                暂不上架
              </a-radio>
              <a-radio :value="1">
                立即上架
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submitForm('ruleForm')">
              确定
            </a-button>
            <a-button style="margin-left: 10px" @click="onCancel">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
    <router-view />
  </div>
</template>
<script>
import { studyPlanList, studyPlanSwitch, addStudyPlan,editStudyPlan, putawayStudyPlan } from '@/request/api/coursManage'
import { studyPlanColumns } from '../constant'
const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
export default {
  name: 'StudyPlan',
  data() {
    return {
      courseNo: this.$route.params.courseNo,
      columns: studyPlanColumns,
      tableLoading: false,
      tableData: [],
      stageSwitch: null,
      modalVisible: false,
      confirmLoading: false,
      ruleForm: {
        stageTitle: '',
        sort: 0,
        putawayFlag: 1
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      
      rules: {
        stageTitle: [
          { required: true, message: '请输入阶段名称', trigger: 'blur' },
        ],
        sort: [
          { required: true, validator: validateSort ,trigger: 'change' },
        ],
        putawayFlag: [{required: true,}]
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { code, data } = await studyPlanList({courseNo: this.courseNo})
      if (code !==200) return
      this.tableData = data.stageList
      this.stageSwitch = !!data.stageSwitch
    },
    // 开关
    async onSwitch(checked) {
      this.stageSwitch = checked
      const params = {
        courseNo: this.courseNo,
        switch: +checked
      }
      const { code } = await studyPlanSwitch(params)
      if (code !== 200) return
      this.$message.success('修改成功')
    },
    showModal(stage) {
      this.ruleForm = stage,
      this.modalVisible = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          +this.ruleForm.stageNo ? this.edit() : this.add()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 添加
    async add() {
      const params = {
        courseNo: this.courseNo,
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await addStudyPlan(params)
      if (code !== 200) return
      this.$message.success('添加成功')
      this.onCancel()
      this.fetchData()
    },
    // 编辑
    async edit() {
      const params = {
        courseNo: this.courseNo,
        ...this.ruleForm,
        sort: +this.ruleForm.sort
      }
      const { code } = await editStudyPlan(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.onCancel()
      this.fetchData()
    },
    // 取消/关闭
    onCancel() {
      this.ruleForm = {
        stageNo: '',
        stageTitle: '',
        sort: 0,
        putawayFlag: 1
      }
      this.modalVisible = false
    },
    // 上架
    async onPut(recode) {
      const params = {
        stageNo: recode.stageNo,
        putawayFlag: recode.putawayFlag ? 0 : 1
      }
      const {code} = await putawayStudyPlan(params)
      if (code !== 200) return
      const text = recode.putawayFlag ? '下架' : '上架'
      this.$message.success(`${text}成功`)
      recode.putawayFlag = recode.putawayFlag ? 0 : 1
    },
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.switch {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
</style>